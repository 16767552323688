import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { time12Formate } from '@/Utils/fliter'
function getHattDay (datDay) {
  datDay = parseInt(datDay)
  if (datDay === 1) {
      return i18n.locale === 'en' ? 'Daily' : 'প্রতিদিন'
  } else if (datDay === 2) {
        return i18n.locale === 'en' ? 'Friday' : 'শুক্রবার'
  } else if (datDay === 3) {
      return i18n.locale === 'en' ? 'Saturday' : 'শনিবার'
  } else if (datDay === 4) {
      return i18n.locale === 'en' ? 'Sunday' : 'রবিবার'
  } else if (datDay === 5) {
    return i18n.locale === 'en' ? 'Monday' : 'সোমবার'
  } else if (datDay === 6) {
    return i18n.locale === 'en' ? 'Tuesday' : 'মঙ্গলবার'
  } else if (datDay === 7) {
    return i18n.locale === 'en' ? 'Wednesday' : 'বুধবার'
  } else if (datDay === 8) {
    return i18n.locale === 'en' ? 'Thursday' : 'বৃহস্পতিবার'
  }
}
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, newDatas, vm, search, labelData) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        pdfContent.push({ text: '', style: 'fertilizer' })
        const allRowsHead = []
        if (search.division_id) {
          const rowItem = [
            { text: vm.$t('globalTrans.division'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.division_name_bn : search.division_name_en, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.district_id) {
          const rowItem = [
            { text: vm.$t('globalTrans.district'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.district_name_bn : search.district_name_en, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.upazila_id) {
          const rowItem = [
            { text: vm.$t('globalTrans.upazila'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.upazila_name_bn : search.upazila_name_en, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (search.market_id) {
          const rowItem = [
            { text: vm.$t('crop_price_config.market'), style: 'td', alignment: 'right' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.market_name_bn : search.market_name_en, style: 'td', alignment: 'left' }
          ]
          allRowsHead.push(rowItem)
        }
        if (allRowsHead.length > 0) {
          pdfContent.push({
            table: {
              widths: ['45%', '5%', '50%'],
              body: allRowsHead
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          })
        }
        pdfContent.push({ text: '', style: 'fertilizer' })
        const labelDatas = labelData.filter(item => item.show === '1')
        const headRows = []
        const width = []
        labelDatas.forEach((info, index) => {
          headRows.push(
            { text: (i18n.locale === 'bn') ? info.label_bn : info.label_en, style: 'th', alignment: 'center', bold: true }
          )
          width.push('*')
        })
        const listData = newDatas.map(item => {
          let hatDays = ''
          item.hat_days.forEach((info1, index1) => {
            if (index1 === 0) {
              hatDays = getHattDay(info1)
            } else {
              hatDays = hatDays + ',\n' + getHattDay(info1)
            }
          })
          const rowData = labelDatas.map((keyItem, index) => {
            if (keyItem.key === 'foundation_year') {
              return { text: vm.$n(item.foundation_year), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'market_type') {
              return { text: vm.getMarket(item.market_type), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'govt_covered') {
              return { text: vm.$n(item.govt_covered), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'govt_open') {
              return { text: vm.$n(item.govt_open), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'private_covered') {
              return { text: vm.$n(item.private_covered), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'private_open') {
              return { text: vm.$n(item.private_open), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'shed_no') {
              return { text: vm.$n(item.shed_no), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'shed_area') {
              return { text: vm.$n(item.shed_area), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'stall_no_agri') {
              return { text: vm.$n(item.stall_no_agri), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'shed_no') {
              return { text: vm.$n(item.shed_no), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'stall_no_nonagri') {
              return { text: vm.$n(item.stall_no_nonagri), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'hat_days') {
              return { text: hatDays, style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'shed_no') {
              return { text: vm.$n(item.shed_no), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'shed_no') {
              return { text: vm.$n(item.shed_no), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'market_time_from') {
              return { text: time12Formate(item.market_time_from), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'market_time_to') {
              return { text: time12Formate(item.market_time_to), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'number_of_buyers') {
              return { text: vm.$n(item.number_of_buyers), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'number_of_sellers') {
              return { text: vm.$n(item.number_of_sellers), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'farmer_share') {
              return { text: vm.$n(item.farmer_share), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'trader_share') {
              return { text: vm.$n(item.trader_share), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'avg_distance') {
              return { text: vm.$n(item.avg_distance), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'data_collection_year') {
              return { text: vm.$n(item.data_collection_year), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'market_representative_name') {
              return { text: vm.$n(item.market_representative_name), style: 'td', alignment: 'center' }
            }
            if (keyItem.key === 'market_representative_mobile') {
              return { text: vm.$n(item.market_representative_mobile), style: 'td', alignment: 'center' }
            }
            return { text: item[keyItem.key], style: 'td', alignment: 'center' }
          })
          return rowData
        })
        listData.unshift(headRows)
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: width,
            body: listData
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'landscape',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('market-directory-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
