<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('damReport.market_directory_report') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                        <b-row>
                                            <b-col lg="6" md="6" sm="12">
                                                <ValidationProvider name="Division">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="division_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('globalTrans.division') }}
                                                        </template>
                                                        <b-form-select
                                                        id="division_id"
                                                        plain
                                                        v-model="formData.division_id"
                                                        :options="divisionList"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" md="6" sm="12">
                                                <ValidationProvider name="District">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="district_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('globalTrans.district') }}
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="formData.district_id"
                                                        :options="districtList"
                                                        id="district_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" md="6" sm="12">
                                                <ValidationProvider name="Subdistrict">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="upazila_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('globalTrans.upazila') }}
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="formData.upazila_id"
                                                        :options="upazilaList"
                                                        id="upazila_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" md="6" sm="12">
                                                <ValidationProvider name="Market">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="market_id"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('crop_price_config.market') }}
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="formData.market_id"
                                                        :options="marketList"
                                                        id="market_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                            </b-col>
                                            <b-col lg="6" sm="12" style="text-align:right" class="float-right mb-1">
                                              <b-dropdown split split-variant="outline-primary" variant="primary" id="dropdown-form" :text="$t('globalTrans.select_field')" ref="dropdown">
                                                <b-dropdown-form>
                                                  <div
                                                    class="form-group col-12 my-0 py-0"
                                                    v-for="(field, index) in labelData"
                                                    :key="index"
                                                    >
                                                    <slot v-if="field.label_en === 'Division' || field.label_en ==='District' || field.label_en ==='Upazila'">
                                                      <b-form-checkbox
                                                        :id="'checkbox-' + field.label_en"
                                                        v-model="field.show"
                                                        :name="'checkbox-' + field.label_en"
                                                        value=1
                                                        unchecked-value=0
                                                        disabled
                                                        @change="excelInputChange()"
                                                      >
                                                        {{ ($i18n.locale==='bn') ? field.label_bn : field.label_en }}
                                                      </b-form-checkbox>
                                                    </slot>
                                                    <slot v-else>
                                                      <b-form-checkbox
                                                        :id="'checkbox-' + field.label_en"
                                                        v-model="field.show"
                                                        :name="'checkbox-' + field.label_en"
                                                        value=1
                                                        unchecked-value=0
                                                        @change="excelInputChange()"
                                                      >
                                                        {{ ($i18n.locale==='bn') ? field.label_bn : field.label_en }}
                                                      </b-form-checkbox>
                                                    </slot>
                                                  </div>
                                                </b-dropdown-form>
                                              </b-dropdown>
                                            </b-col>
                                        </b-row>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.search') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
                <b-row >
                  <b-col md="12">
                    <b-overlay :show="loading">
                      <iq-card v-if='datas.length'>
                        <template v-slot:body>
                          <b-row>
                            <b-col md="12">
                              <iq-card>
                                <template v-slot:headerTitle>
                                  <h4 class="card-title">{{ $t('damReport.market_directory_report') }}</h4>
                                </template>
                                <template v-slot:headerAction>
                                  <b-button class="btn_add_new mr-2" @click="pdfExport">
                                    <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                                  </b-button>
                                   <export-excel
                                      class="btn btn_add_new"
                                      :data="dataCustomizeExcel()"
                                      :title="headerValue"
                                      :fields= "json_fields"
                                      worksheet="Market Direactory Report"
                                      :before-generate = "headerDataExcel"
                                      name="market-directory-report.xls">
                                      <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                                    </export-excel>
                                </template>
                                <template v-slot:body>
                                  <b-overlay>
                                    <b-row mt-5>
                                      <b-col md="12" class="table-responsive">
                                        <div style="border: 2px solid;margin:10px;padding:10px">
                                          <b-row>
                                            <b-col>
                                              <list-report-head :base-url="warehouseServiceBaseUrl" :uri="reportHeadingList" :org-id="13">
                                                 <template v-slot:projectNameSlot>
                                                  {{ }}
                                                </template>
                                                {{ $t('damReport.market_directory_report') }}
                                              </list-report-head>
                                            </b-col>
                                          </b-row>
                                          <b-row>
                                            <b-col>
                                              <div class="text-center">
                                                <table style="width:100%;color:black;">
                                                  <tr v-if="formData.division_id">
                                                    <td align="right" style="width:40%">{{ $t('globalTrans.division') }}</td>
                                                    <td align="center" style="width:5%">:</td>
                                                    <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? formData.division_name_bn : formData.division_name_en }}</td>
                                                  </tr>
                                                  <tr v-if="formData.district_id">
                                                    <td align="right" style="width:40%">{{ $t('globalTrans.district') }}</td>
                                                    <td align="center" style="width:5%">:</td>
                                                    <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? formData.district_name_bn : formData.district_name_en }}</td>
                                                  </tr>
                                                  <tr v-if="formData.upazila_id">
                                                    <td align="right" style="width:40%">{{ $t('globalTrans.upazila') }}</td>
                                                    <td align="center" style="width:5%">:</td>
                                                    <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? formData.upazila_name_bn : formData.upazila_name_en }}</td>
                                                  </tr>
                                                  <tr v-if="formData.market_id">
                                                    <td align="right" style="width:40%">{{ $t('crop_price_config.market') }}</td>
                                                    <td align="center" style="width:5%">:</td>
                                                    <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? formData.market_name_bn : formData.market_name_en }}</td>
                                                  </tr>
                                                </table>
                                              </div>
                                            </b-col>
                                          </b-row>
                                          <b-row>
                                            <b-col>
                                              <div class="tableFixHead col-12 scrolled-td-table-wrapper">
                                                <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                                                  <b-table head-variant="primary" class="tg" :items="datas" :sticky-header="stickyHeader" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                                                    <template v-slot:cell(foundation_year)="data">
                                                      {{ $n(data.item.foundation_year, { useGrouping: false }) }}
                                                    </template>
                                                    <template v-slot:cell(market_type)="data">
                                                      {{ getMarket(data.item.market_type) }}
                                                    </template>
                                                    <template v-slot:cell(govt_covered)="data">
                                                      {{ $n(data.item.govt_covered) }}
                                                    </template>
                                                    <template v-slot:cell(govt_open)="data">
                                                      {{ $n(data.item.govt_open) }}
                                                    </template>
                                                    <template v-slot:cell(private_covered)="data">
                                                      {{ $n(data.item.private_covered) }}
                                                    </template>
                                                    <template v-slot:cell(private_open)="data">
                                                      {{ $n(data.item.private_open) }}
                                                    </template>
                                                    <template v-slot:cell(shed_no)="data">
                                                      {{ $n(data.item.shed_no) }}
                                                    </template>
                                                     <template v-slot:cell(shed_area)="data">
                                                      {{ $n(data.item.shed_area) }}
                                                    </template>
                                                     <template v-slot:cell(stall_no_agri)="data">
                                                      {{ $n(data.item.stall_no_agri) }}
                                                    </template>
                                                    <template v-slot:cell(stall_no_nonagri)="data">
                                                      {{ $n(data.item.stall_no_nonagri) }}
                                                    </template>
                                                    <template v-slot:cell(hat_days)="data">
                                                      <span v-for="(info1, index1) in data.item.hat_days" :key="index1">
                                                        {{getHattDay(info1)}}
                                                      </span>
                                                    </template>
                                                    <template v-slot:cell(market_time_from)="data">
                                                      {{ data.item.market_time_from | time12Formate }}
                                                    </template>
                                                    <template v-slot:cell(market_time_to)="data">
                                                      {{ data.item.market_time_to | time12Formate }}
                                                    </template>
                                                    <template v-slot:cell(infrastructure_id)="data">
                                                      {{ getInfratureData(data.item.infrastructure_id) }}
                                                    </template>
                                                    <template v-slot:cell(communication_linkage_id)="data">
                                                      {{ getCommunicationLinkage(data.item.communication_linkage_id) }}
                                                    </template>
                                                    <template v-slot:cell(number_of_buyers)="data">
                                                      {{ $n(data.item.number_of_buyers) }}
                                                    </template>
                                                    <template v-slot:cell(number_of_sellers)="data">
                                                      {{ $n(data.item.number_of_sellers) }}
                                                    </template>
                                                    <template v-slot:cell(farmer_share)="data">
                                                      {{ $n(data.item.farmer_share) }}
                                                    </template>
                                                    <template v-slot:cell(trader_share)="data">
                                                      {{ $n(data.item.trader_share) }}
                                                    </template>
                                                    <template v-slot:cell(product_destination)="data">
                                                      {{ getProductDesignation(data.item.product_destination) }}
                                                    </template>
                                                    <template v-slot:cell(vehicle_id)="data">
                                                      {{ getVechile(data.item.vehicle_id) }}
                                                    </template>
                                                    <template v-slot:cell(avg_distance)="data">
                                                      {{ $n(data.item.avg_distance) }}
                                                    </template>
                                                    <template v-slot:cell(data_collection_year)="data">
                                                      {{ $n(data.item.data_collection_year) }}
                                                    </template>
                                                    <template v-slot:cell(market_representative_mobile)="data">
                                                      {{ $i18n.locale === 'bn' ? '০' : '0' }}{{$n(data.item.market_representative_mobile,{ useGrouping:false})}}
                                                    </template>
                                                    <template v-slot:cell(latitude)="data">
                                                      {{ data.item.latitude }}
                                                    </template>
                                                    <template v-slot:cell(longitude)="data">
                                                      {{ data.item.longitude }}
                                                    </template>
                                                  </b-table>
                                                </slot>
                                              </div>
                                            </b-col>
                                          </b-row>
                                        </div>
                                      </b-col>
                                    </b-row>
                                  </b-overlay>
                                </template>
                              </iq-card>
                            </b-col>
                          </b-row>
                        </template>
                      </iq-card>
                      <div class="panel-body text-center mt-3" v-else>
                        <h6 class="text-danger" v-if="!firstTime">{{ $t('globalTrans.noDataFound') }}</h6>
                      </div>
                    </b-overlay>
                  </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>
<style >
.tableFixHead th div {
  color: white;
}
.tableFixHead  th {
  background: #609513;
  /* color: var(--iq-white); */
}
.sticky-col {
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
}

.first-col {
  width: 93px;
  min-width: 93px;
  max-width: 93px;
  left: 0px;
  background-color: white;
}

.second-col {
  width: 93px;
  min-width: 140px;
  max-width: 140px;
  left: 93px;
  background-color: white;
}
.third-col {
  width: 90px;
  min-width: 140px;
  max-width: 140px;
  left: 233px;
  background-color: white;
}
.underzindex {
  z-index: 3 !important;
}
.b-table-sticky-header > .table.b-table > thead > tr > th {
  position: sticky !important;
  top: 0;
}
.topzindex {
  z-index: 5 !important;
}
.rightzindex {
  z-index: 4 !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import { marketDirectoryReport, reportHeadingList } from '../../api/routes'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
import { time12Formate } from '@/Utils/fliter'
import Store from '@/store'
import i18n from '@/i18n'
Vue.use(excel)

const defaultColumn = [
  { label_en: i18n.messages.en.globalTrans.division, label_bn: i18n.messages.bn.globalTrans.division, class: 'sticky-col first-col underzindex', sortable: true, thClass: 'sticky-col first-col topzindex', show: '1', excelKey: 'division', variant: 'check', order: 1 },
  { label_en: i18n.messages.en.globalTrans.district, label_bn: i18n.messages.bn.globalTrans.district, class: 'sticky-col second-col underzindex', sortable: true, thClass: 'sticky-col second-col topzindex', show: '1', excelKey: 'district', variant: 'check', order: 2 },
  { label_en: i18n.messages.en.globalTrans.upazila, label_bn: i18n.messages.bn.globalTrans.upazila, class: 'sticky-col third-col underzindex', sortable: true, thClass: 'sticky-col third-col topzindex', show: '1', excelKey: 'upazilla', variant: 'check', order: 3 },
  { label_en: i18n.messages.en.crop_price_config.market_name, label_bn: i18n.messages.bn.crop_price_config.market_name, class: 'text-left', thClass: 'rightzindex', sortable: true, show: '1', excelKey: 'market', order: 4 },
  { label_en: i18n.messages.en.crop_price_config.foundation_year, label_bn: i18n.messages.bn.crop_price_config.foundation_year, thClass: 'rightzindex', class: 'text-left', sortable: true, show: '1', excelKey: 'foundationYear', order: 5 },
  { label_en: i18n.messages.en.crop_price_config.Village, label_bn: i18n.messages.bn.crop_price_config.Village, class: 'text-left', thClass: 'rightzindex', sortable: true, show: '1', excelKey: 'village', order: 6 },
  { label_en: i18n.messages.en.crop_price_config.PostOffice, label_bn: i18n.messages.bn.crop_price_config.PostOffice, class: 'text-left', thClass: 'rightzindex', sortable: true, show: '1', excelKey: 'post_office', order: 7 },
  { label_en: i18n.messages.en.crop_price_config.p_t_u, label_bn: i18n.messages.bn.crop_price_config.p_t_u, class: 'text-left', thClass: 'rightzindex', sortable: true, show: '1', excelKey: 'union', order: 8 },
  { label_en: i18n.messages.en.crop_price_config.MarketType, label_bn: i18n.messages.bn.crop_price_config.MarketType, class: 'text-left', thClass: 'rightzindex', sortable: true, show: '1', excelKey: 'marketType', order: 8 },
  { label_en: i18n.messages.en.crop_price_config.GovtCovered, label_bn: i18n.messages.bn.crop_price_config.GovtCovered, class: 'text-left', thClass: 'rightzindex', sortable: true, show: '0', excelKey: 'govtConvered', order: 9 },
  { label_en: i18n.messages.en.crop_price_config.GovtOpen, label_bn: i18n.messages.bn.crop_price_config.GovtOpen, class: 'text-left', sortable: true, show: '0', excelKey: 'govtOpen', order: 10 },
  { label_en: i18n.messages.en.crop_price_config.PrivateCovered, label_bn: i18n.messages.bn.crop_price_config.PrivateCovered, class: 'text-left', sortable: true, show: '0', excelKey: 'privateCovered', order: 11 },
  { label_en: i18n.messages.en.crop_price_config.PrivateOpen, label_bn: i18n.messages.bn.crop_price_config.PrivateOpen, class: 'text-left', sortable: true, show: '0', excelKey: 'privateOpen', order: 12 },
  { label_en: i18n.messages.en.crop_price_config.ShedNo, label_bn: i18n.messages.bn.crop_price_config.ShedNo, class: 'text-left', sortable: true, show: '0', excelKey: 'shedNo', order: 13 },
  { label_en: i18n.messages.en.crop_price_config.ShedArea, label_bn: i18n.messages.bn.crop_price_config.ShedArea, class: 'text-left', sortable: true, show: '0', excelKey: 'shedArea', order: 15 },
  { label_en: i18n.messages.en.crop_price_config.StallCountAgr, label_bn: i18n.messages.bn.crop_price_config.StallCountAgr, class: 'text-left', sortable: true, show: '0', excelKey: 'stallNoAgri', order: 16 },
  { label_en: i18n.messages.en.crop_price_config.StallCountNonAgr, label_bn: i18n.messages.bn.crop_price_config.StallCountNonAgr, class: 'text-left', sortable: true, show: '0', excelKey: 'stallNoNonagri', order: 17 },
  { label_en: i18n.messages.en.crop_price_config.HatDays, label_bn: i18n.messages.bn.crop_price_config.HatDays, class: 'text-left', sortable: true, show: '0', excelKey: 'hatDays', order: 18 },
  { label_en: i18n.messages.en.crop_price_config.market_time_from, label_bn: i18n.messages.bn.crop_price_config.market_time_from, class: 'text-left', sortable: true, show: '0', excelKey: 'marketTimeFrom', order: 19 },
  { label_en: i18n.messages.en.crop_price_config.market_time_to, label_bn: i18n.messages.bn.crop_price_config.market_time_to, class: 'text-left', sortable: true, show: '0', excelKey: 'marketTimeTo', order: 20 },
  { label_en: i18n.messages.en.crop_price_config.infrastructure, label_bn: i18n.messages.bn.crop_price_config.infrastructure, class: 'text-left', sortable: true, show: '0', excelKey: 'infrastructureId', order: 21 },
  { label_en: i18n.messages.en.crop_price_config.communcication_Linkage, label_bn: i18n.messages.bn.crop_price_config.communcication_Linkage, class: 'text-left', sortable: true, show: '0', excelKey: 'communicationLinkageId', order: 22 },
  { label_en: i18n.messages.en.crop_price_config.BuyerSellecount, label_bn: i18n.messages.bn.crop_price_config.BuyerSellecount, class: 'text-left', sortable: true, show: '0', excelKey: 'numberOfBuyers', order: 23 },
  { label_en: i18n.messages.en.crop_price_config.ProduceAssemblage, label_bn: i18n.messages.bn.crop_price_config.ProduceAssemblage, class: 'text-left', sortable: true, show: '0', excelKey: 'numberOfSellers', order: 24 },
  { label_en: i18n.messages.en.crop_price_config.FarmerShare, label_bn: i18n.messages.bn.crop_price_config.FarmerShare, class: 'text-left', sortable: true, show: '0', excelKey: 'farmerShare', order: 25 },
  { label_en: i18n.messages.en.crop_price_config.TraderShare, label_bn: i18n.messages.bn.crop_price_config.TraderShare, class: 'text-left', sortable: true, show: '0', excelKey: 'traderShare', order: 26 },
  { label_en: i18n.messages.en.crop_price_config.productionDesignation, label_bn: i18n.messages.bn.crop_price_config.productionDesignation, class: 'text-left', sortable: true, show: '0', excelKey: 'productDestination', order: 27 },
  { label_en: i18n.messages.en.crop_price_config.vehicle, label_bn: i18n.messages.bn.crop_price_config.vehicle, class: 'text-left', sortable: true, show: '0', excelKey: 'vehicleId', order: 28 },
  { label_en: i18n.messages.en.crop_price_config.average_distrance, label_bn: i18n.messages.bn.crop_price_config.average_distrance, class: 'text-left', sortable: true, show: '0', excelKey: 'avgDistance', order: 29 },
  { label_en: i18n.messages.en.crop_price_config.data_collection_year, label_bn: i18n.messages.bn.crop_price_config.data_collection_year, class: 'text-left', sortable: true, show: '0', excelKey: 'dataCollectionYear', order: 30 },
  { label_en: i18n.messages.en.crop_price_config.name, label_bn: i18n.messages.bn.crop_price_config.name, class: 'text-left', sortable: true, show: '0', excelKey: 'marketRepresentativeName', order: 31 },
  { label_en: i18n.messages.en.crop_price_config.tel_mobile_number, label_bn: i18n.messages.bn.crop_price_config.tel_mobile_number, class: 'text-left', sortable: true, show: '0', excelKey: 'marketRepresentativeMobile', order: 32 },
  { label_en: i18n.messages.en.crop_price_config.Latitude, label_bn: i18n.messages.bn.crop_price_config.Latitude, class: 'text-left', sortable: false, show: '0', excelKey: 'latitude', order: 33 },
  { label_en: i18n.messages.en.crop_price_config.Longitude, label_bn: i18n.messages.bn.crop_price_config.Longitude, class: 'text-left', sortable: false, show: '0', excelKey: 'Latitude', order: 34 }
]

export default {
    props: [],
    components: {
        ValidationObserver,
        ValidationProvider,
        ListReportHead
    },
    created () {
      this.labelData = this.labelList
      if (this.$store.state.Auth.activeRoleId !== 1) {
        this.formData = Object.assign({}, this.formData, {
          divisionList: JSON.stringify(this.loggedUserPrivilege.division_id),
          districtList: JSON.stringify(this.loggedUserPrivilege.district_id),
          upazillaList: JSON.stringify(this.loggedUserPrivilege.upazila_id),
          marketList: JSON.stringify(this.loggedUserPrivilege.market_id)
        })
      }
      this.excelInputChange()
    },
    data () {
        return {
          noCollapse: false,
          stickyHeader: true,
          HeaderExcel: [],
          saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
          formData: {
            division_name_en: '',
            division_name_bn: '',
            division_id: 0,
            district_id: 0,
            upazila_id: 0,
            market_id: 0,
            divisionList: [],
            districtList: [],
            upazillaList: [],
            marketList: []
          },
          currentDate: '',
          listItemofyerterday: '',
          districtList: [],
          upazilaList: [],
          marketList: [],
          commoditySubGroupList: [],
          warehouseServiceBaseUrl: warehouseServiceBaseUrl,
          reportHeadingList: reportHeadingList,
          headerExcelDefault: {
            orgName: null,
            orgNameBn: null,
            address: null,
            address_bn: null
          },
          datas: [],
          labelData: [],
          excelColumnBn: {},
          excelColumn: {},
          firstTime: true,
          showData: false
        }
    },
    mounted () {
      core.index()
      flatpickr('.datepicker', {})
      this.headerDataExcel()
      this.AveragePriceText = (parseInt(this.formData.HighestPrice) + parseInt(this.formData.LowestPrice)) / 2
    },
    computed: {
      json_fields: function () {
        return this.$i18n.locale === 'bn' ? this.excelColumnBn : this.excelColumn
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          const labelData = {}
          labelData.label = this.$i18n.locale === 'bn' ? item.label_bn : item.label_en
          return Object.assign(item, labelData)
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'division_name_bn' },
            { key: 'distict_name_bn' },
            { key: 'upazila_name_bn' },
            { key: 'market_name_bn' },
            { key: 'foundation_year' },
            { key: 'village' },
            { key: 'post_office' },
            { key: 'union' },
            { key: 'market_type' },
            { key: 'govt_covered' },
            { key: 'govt_open' },
            { key: 'private_covered' },
            { key: 'private_open' },
            { key: 'shed_no' },
            { key: 'shed_area' },
            { key: 'stall_no_agri' },
            { key: 'stall_no_nonagri' },
            { key: 'hat_days' },
            { key: 'market_time_from' },
            { key: 'market_time_to' },
            { key: 'infrastructure_id' },
            { key: 'communication_linkage_id' },
            { key: 'number_of_buyers' },
            { key: 'number_of_sellers' },
            { key: 'farmer_share' },
            { key: 'trader_share' },
            { key: 'product_destination' },
            { key: 'vehicle_id' },
            { key: 'avg_distance' },
            { key: 'data_collection_year' },
            { key: 'market_representative_name' },
            { key: 'market_representative_mobile' },
            { key: 'latitude' },
            { key: 'longitude' }
          ]
        } else {
          keys = [
            { key: 'division_name' },
            { key: 'distict_name' },
            { key: 'upazila_name' },
            { key: 'market_name' },
            { key: 'foundation_year' },
            { key: 'village' },
            { key: 'post_office' },
            { key: 'union' },
            { key: 'market_type' },
            { key: 'govt_covered' },
            { key: 'govt_open' },
            { key: 'private_covered' },
            { key: 'private_open' },
            { key: 'shed_no' },
            { key: 'shed_area' },
            { key: 'stall_no_agri' },
            { key: 'stall_no_nonagri' },
            { key: 'hat_days' },
            { key: 'market_time_from' },
            { key: 'market_time_to' },
            { key: 'infrastructure_id' },
            { key: 'communication_linkage_id' },
            { key: 'number_of_buyers' },
            { key: 'number_of_sellers' },
            { key: 'farmer_share' },
            { key: 'trader_share' },
            { key: 'product_destination' },
            { key: 'vehicle_id' },
            { key: 'avg_distance' },
            { key: 'data_collection_year' },
            { key: 'market_representative_name' },
            { key: 'market_representative_mobile' },
            { key: 'latitude' },
            { key: 'longitude' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      labelList: function () {
        const dataList = defaultColumn
        return dataList.map(item => {
            if (this.$i18n.locale === 'bn') {
              const data = { value: item.label_en, text: item.label_bn }
              return Object.assign({}, item, data)
            } else {
              const data = { value: item.label_en, text: item.label_en }
              return Object.assign({}, item, data)
            }
        })
      },
      headerValue: function () {
          const headerVal = []
          if (this.$i18n.locale === 'en') {
            headerVal[0] = this.headerExcelDefault.orgName
            headerVal[1] = this.headerExcelDefault.address
            headerVal[2] = this.$t('damReport.market_directory_report')
            if (this.formData.division_id) {
              headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.formData.division_name_en
            }
            if (this.formData.district_id) {
              headerVal[4] = this.$t('globalTrans.district') + ': ' + this.formData.district_name_en
            }
            if (this.formData.division_id) {
              headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.formData.upazila_name_en
            }
            if (this.formData.market_id) {
              headerVal[6] = this.$t('crop_price_config.market') + ': ' + this.formData.market_name_en
            }
          } else {
            headerVal[0] = this.headerExcelDefault.orgNameBn
            headerVal[1] = this.headerExcelDefault.address_bn
            headerVal[2] = this.$t('damReport.market_directory_report')
            if (this.formData.division_id) {
              headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.formData.division_name_bn
            }
            if (this.formData.district_id) {
              headerVal[4] = this.$t('globalTrans.district') + ': ' + this.formData.district_name_bn
            }
            if (this.formData.division_id) {
              headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.formData.upazila_name_bn
            }
            if (this.formData.market_id) {
              headerVal[6] = this.$t('crop_price_config.market') + ': ' + this.formData.market_name_bn
            }
          }
          return headerVal
      },
      divisionList: function () {
        return this.$store.state.agriMarketing.damData.divisionList
      },
      priceEntryTypeList: function () {
        return this.$store.state.agriMarketing.commonObj.priceEntryTypeList
      },
      timePreiodList: function () {
        return this.$store.state.agriMarketing.commonObj.timePreiodList
      },
      i18 () {
        return this.$i18n.locale
      },
      priceTypeList: function () {
        return this.$store.state.agriMarketing.commonObj.priceTypeList
      },
      loading () {
          return this.$store.state.commonObj.loading
      },
      loggedUserPrivilege: function () {
        return this.$store.state.agriMarketing.commonObj.loggedUserPrivilege
      }
    },
    watch: {
        'formData.division_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.formData.district_id = 0
                this.formData.upazila_id = 0
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'formData.district_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.formData.upazila_id = 0
                this.upazilaList = this.getUpazilaList(newVal)
            }
        },
        'formData.upazila_id': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.marketList = this.getMarketList(newVal)
            }
        }
    },
    methods: {
      excelInputChange () {
        const labelColumn = {}
        const labelColumnBn = {}
        this.labelData.map((item, index) => {
          if (item.show === '1') {
            labelColumn[item.label_en] = item.excelKey
            labelColumnBn[item.label_bn] = item.excelKey
          }
        })
        this.excelColumn = labelColumn
        this.excelColumnBn = labelColumnBn
      },
      dataCustomizeExcel () {
        try {
            if (typeof this.datas !== 'undefined') {
                const listData = this.datas
                listData.map(item => {
                    if (this.$i18n.locale === 'bn') {
                        // item.sl_no = this.$n(slNo++)
                        item.division = item.division_name_bn
                        item.district = item.distict_name_bn
                        item.upazilla = item.upazila_name_bn
                        item.market = item.market_name_bn
                        item.marketType = this.getMarket(item.market_type)
                        item.foundationYear = this.$n(item.foundation_year)
                        item.govtConvered = this.$n(item.govt_covered)
                        item.govtOpen = this.$n(item.govt_open)
                        item.privateCovered = this.$n(item.private_covered)
                        item.privateOpen = this.$n(item.private_open)
                        item.shedNo = this.$n(item.shed_no)
                        item.shedArea = this.$n(item.shed_area)
                        item.stallNoAgri = this.$n(item.stall_no_agri)
                        item.stallNoNonagri = this.$n(item.stall_no_nonagri)
                        item.hatDays = this.getHattDay(item.hat_days)
                        item.marketTimeFrom = time12Formate(item.market_time_from)
                        item.marketTimeTo = time12Formate(item.market_time_to)
                        item.infrastructureId = this.getInfratureData(item.infrastructure_id)
                        item.communicationLinkageId = this.getCommunicationLinkage(item.communication_linkage_id)
                        item.vehicleId = this.getVechile(item.vehicle_id)
                        item.numberOfBuyers = this.$t(item.number_of_buyers)
                        item.numberOfSellers = this.$t(item.number_of_sellers)
                        item.farmerShare = this.$t(item.farmer_share)
                        item.traderShare = this.$t(item.trader_share)
                        item.productDestination = this.getProductDesignation(item.product_destination)
                        item.avgDistance = this.$t(item.avg_distance)
                        item.dataCollectionYear = this.$t(item.data_collection_year)
                        item.marketRepresentativeName = item.market_representative_name
                        item.marketRepresentativeMobile = item.market_representative_mobile
                    } else {
                        // item.sl_no = slNo++
                        item.division = item.division_name
                        item.district = item.distict_name
                        item.upazilla = item.upazila_name
                        item.market = item.market_name
                        item.marketType = this.getMarket(item.market_type)
                        item.foundationYear = this.$n(item.foundation_year)
                        item.govtConvered = this.$n(item.govt_covered)
                        item.govtOpen = this.$n(item.govt_open)
                        item.privateCovered = this.$n(item.private_covered)
                        item.privateOpen = this.$n(item.private_open)
                        item.shedNo = this.$n(item.shed_no)
                        item.shedArea = this.$n(item.shed_area)
                        item.stallNoAgri = this.$n(item.stall_no_agri)
                        item.stallNoNonagri = this.$n(item.stall_no_nonagri)
                        item.hatDays = this.getHattDay(item.hat_days)
                        item.marketTimeFrom = time12Formate(item.market_time_from)
                        item.marketTimeTo = time12Formate(item.market_time_to)
                        item.infrastructureId = this.getInfratureData(item.infrastructure_id)
                        item.communicationLinkageId = this.getCommunicationLinkage(item.communication_linkage_id)
                        item.vehicleId = this.getVechile(item.vehicle_id)
                        item.numberOfBuyers = this.$t(item.number_of_buyers)
                        item.numberOfSellers = this.$t(item.number_of_sellers)
                        item.farmerShare = this.$t(item.farmer_share)
                        item.traderShare = this.$t(item.trader_share)
                        item.productDestination = this.getProductDesignation(item.product_destination)
                        item.avgDistance = this.$t(item.avg_distance)
                        item.dataCollectionYear = this.$t(item.data_collection_year)
                        item.marketRepresentativeName = item.market_representative_name
                        item.marketRepresentativeMobile = item.market_representative_mobile
                    }
                    return Object.assign({}, item)
                })
                return listData
            } else {
                return []
            }
        } catch (e) {
            return []
        }
      },
      getMarket (marketType) {
          marketType = parseInt(marketType)
          if (marketType === 1) {
              return this.$i18n.locale === 'en' ? 'Assemble' : 'একত্রিত করা'
          } else if (marketType === 2) {
                return this.$i18n.locale === 'en' ? 'Primary' : 'প্রাথমিক'
          } else if (marketType === 3) {
              return this.$i18n.locale === 'en' ? 'Retail' : 'খুচরা'
          } else if (marketType === 4) {
              return this.$i18n.locale === 'en' ? 'Wholesale' : 'পাইকারি'
          } else if (marketType === 5) {
              return this.$i18n.locale === 'en' ? 'Wholesale Cum Retail' : 'খুচরা বিক্রয়'
          }
      },
      getHattDay (datDay) {
          datDay = parseInt(datDay)
          if (datDay === 1) {
              return this.$i18n.locale === 'en' ? 'Daily' : 'প্রতিদিন'
          } else if (datDay === 2) {
                return this.$i18n.locale === 'en' ? 'Friday' : 'শুক্রবার'
          } else if (datDay === 3) {
              return this.$i18n.locale === 'en' ? 'Saturday' : 'শনিবার'
          } else if (datDay === 4) {
              return this.$i18n.locale === 'en' ? 'Sunday' : 'রবিবার'
          } else if (datDay === 5) {
            return this.$i18n.locale === 'en' ? 'Monday' : 'সোমবার'
          } else if (datDay === 6) {
            return this.$i18n.locale === 'en' ? 'Tuesday' : 'মঙ্গলবার'
          } else if (datDay === 7) {
            return this.$i18n.locale === 'en' ? 'Wednesday' : 'বুধবার'
          } else if (datDay === 8) {
            return this.$i18n.locale === 'en' ? 'Thursday' : 'বৃহস্পতিবার'
          }
      },
      getCommunicationLinkage (linkageIds) {
        const linkageList = this.$store.state.agriMarketing.commonObj.communicationLinkageList
        const communicationLinkage = []
        linkageIds.map(e => {
          const tmpLinkage = linkageList.find(linkage => linkage.value === e)
          communicationLinkage.push(this.$i18n.locale === 'bn' ? tmpLinkage.text_bn : tmpLinkage.text_en)
        })
        return communicationLinkage.join(', ')
      },
      getInfratureData (InfractureIds) {
        const infractureList = this.$store.state.agriMarketing.commonObj.infrastructureList
        const infracture = []
        InfractureIds.map(e => {
          const tmpInfracture = infractureList.find(infracture => infracture.value === e)
          infracture.push(this.$i18n.locale === 'bn' ? tmpInfracture.text_bn : tmpInfracture.text_en)
        })
        return infracture.join(', ')
      },
      getVechile (vechilesIds) {
        const vechileList = this.$store.state.agriMarketing.commonObj.vehicleList
        const vechile = []
        vechilesIds.map(e => {
          const tmpVechile = vechileList.find(vechile => vechile.value === e)
          vechile.push(this.$i18n.locale === 'bn' ? tmpVechile.text_bn : tmpVechile.text_en)
        })
        return vechile.join(', ')
      },
      getProductDesignation (productDesigantion) {
        const desigantionList = this.$store.state.agriMarketing.commonObj.designationOfProductList
        const desigationProduct = []
        productDesigantion.map(e => {
          const tmpDesignation = desigantionList.find(desigationProduct => desigationProduct.value === e)
          desigationProduct.push(this.$i18n.locale === 'bn' ? tmpDesignation !== undefined ? tmpDesignation.text_bn : '' : tmpDesignation !== undefined ? tmpDesignation.text_en : '')
        })
        return desigationProduct.join(', ')
      },
      pdfExport () {
        const reportTitle = this.$t('damReport.market_directory_report')
        ExportPdf.exportPdfDetails(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', 13, reportTitle, this.datas, this, this.formData, this.labelData)
      },
      onChangeFile (e) {
          this.attachmentDemo = e.target.files[0]
      },
      getEditingData () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
      },
      headerDataExcel () {
        RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail/13').then(response => {
        if (response.success) {
          const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 13)
          const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
          const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
          this.headerExcelDefault.orgName = orgName
          this.headerExcelDefault.orgNameBn = orgNameBn
          this.headerExcelDefault.address = response.data.address
          this.headerExcelDefault.address_bn = response.data.address_bn
        }
      })
      },
      async saveData () {
        Store.commit('mutateCommonProperties', {
            loading: true
        })
        const divisionObj = this.$store.state.agriMarketing.damData.divisionList.find(item => item.value === this.formData.division_id)
        this.formData.division_name_en = typeof divisionObj !== 'undefined' ? divisionObj.text_en : ''
        this.formData.division_name_bn = typeof divisionObj !== 'undefined' ? divisionObj.text_bn : ''
        const districtListObj = this.$store.state.agriMarketing.damData.districtList.find(item => item.value === this.formData.district_id)
        this.formData.district_name_en = typeof districtListObj !== 'undefined' ? districtListObj.text_en : ''
        this.formData.district_name_bn = typeof districtListObj !== 'undefined' ? districtListObj.text_bn : ''
        const upazilaListObj = this.$store.state.agriMarketing.damData.upazilaList.find(item => item.value === this.formData.upazila_id)
        this.formData.upazila_name_en = typeof upazilaListObj !== 'undefined' ? upazilaListObj.text_en : ''
        this.formData.upazila_name_bn = typeof upazilaListObj !== 'undefined' ? upazilaListObj.text_bn : ''
        const marketListObj = this.$store.state.agriMarketing.commonObj.marketList.find(item => item.value === this.formData.market_id)
        this.formData.market_name_en = typeof marketListObj !== 'undefined' ? marketListObj.text_en : ''
        this.formData.market_name_bn = typeof marketListObj !== 'undefined' ? marketListObj.text_bn : ''
        await RestApi.getData(agriMarketingServiceBaseUrl, marketDirectoryReport, this.formData).then(response => {
          if (response.success) {
            Store.commit('mutateCommonProperties', {
              loading: false
            })
            if (response.data) {
              this.datas = this.formatData(response.data)
              this.firstTime = false
            }
          } else {
            this.datas = []
            Store.commit('mutateCommonProperties', {
              loading: false
            })
            this.firstTime = false
          }
        })
      },
      formatData (data) {
        const formattedData = data.map(item => {
          const divisionData = this.$store.state.agriMarketing.damData.divisionList.find(division => division.value === item.division_id)
          const districtData = this.$store.state.agriMarketing.damData.districtList.find(district => district.value === item.district_id)
          const upazilaData = this.$store.state.agriMarketing.damData.upazilaList.find(upazilla => upazilla.value === item.upazila_id)
          const tmpBindingData = {
            division_name: divisionData !== undefined ? divisionData.text_en : '',
            division_name_bn: divisionData !== undefined ? divisionData.text_bn : '',
            distict_name: districtData !== undefined ? districtData.text_en : '',
            distict_name_bn: districtData !== undefined ? districtData.text_bn : '',
            upazila_name: upazilaData !== undefined ? upazilaData.text_en : '',
            upazila_name_bn: upazilaData !== undefined ? upazilaData.text_bn : ''
          }
          return Object.assign({}, item, tmpBindingData)
        })
        return formattedData
      },
      getDistrictList (divisionId = null) {
          return this.$store.state.agriMarketing.damData.districtList.filter(item => item.division_id === divisionId)
      },
      getUpazilaList (districtId = null) {
          const upazilaList = this.$store.state.agriMarketing.damData.upazilaList
          if (districtId) {
              return upazilaList.filter(upazila => upazila.district_id === districtId)
          }
          return upazilaList
      },
      getMarketList (upazillaId = null) {
          const dataList = this.$store.state.agriMarketing.commonObj.marketList.filter(item => item.upazila_id === upazillaId)
          return dataList.map(item => {
              if (this.$i18n.locale === 'bn') {
                  return { value: item.value, text: item.text_bn }
              } else {
                  return { value: item.value, text: item.text }
              }
          })
      },
      getPriceTypeList: function (entryType) {
          const dataList = this.$store.state.agriMarketing.commonObj.priceTypeList.filter(item => item.entry_type === entryType)
          return dataList.map(item => {
              if (this.$i18n.locale === 'bn') {
                  return { value: item.value, text: item.text_bn }
              } else {
                  return { value: item.value, text: item.text }
              }
          })
      }
  }
}
</script>
